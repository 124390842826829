import React from 'react'

// Images
import Quote from 'img/quote.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface SeeAllVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Vacancies
}

const StyledSection = styled.section`
  padding: 150px 0;

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }
`

const Block = styled.div`
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 80px 40px 40px 250px;
  background-color: ${({ theme }) => theme.color.light};

  & p {
    font-size: 24px;
    line-height: 30px;

    & a {
      font-size: initial;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 80px 40px 40px 40px;
  }
`

const Title = styled.div`
  & h2 {
    font-size: 60px;
    line-height: 75px;
  }
`

const BackgroundImage = styled.div`
  position: absolute;
  width: 450px;
  height: 350px;
  bottom: -260px;
  left: -55px;

  @media screen and (max-width: 991px) {
    width: 300px;
    height: 250px;
    bottom: -15px;
    left: 100px;
  }
`

const ImageWrapper = styled.div`
  width: 380px;
  height: 425px;
  position: absolute;
  bottom: 0;
  left: -150px;

  @media screen and (max-width: 991px) {
    left: unset;
    right: -15px;
    width: 150px;
    height: auto;
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 180px;

  @media screen and (max-width: 991px) {
    left: 15px;
    top: 15px;
  }
`

const SeeAllVacancies: React.FC<SeeAllVacanciesProps> = ({ fields }) => (
  <StyledSection>
    <div className="container py-lg-5">
      <div className="row py-lg-5 justify-content-evenly">
        <div className="col-lg-3">
          <Title className="position-relative">
            <ParseContent content={fields.title || ''} />
            <BackgroundImage>
              <Plaatjie image={fields.backgroundimage} alt="" />
            </BackgroundImage>
          </Title>
        </div>
        <div className="col-lg-7">
          <Block className="position-relative">
            <SvgWrapper>
              <Quote />
            </SvgWrapper>
            <ImageWrapper>
              <Plaatjie image={fields.image} alt="" />
            </ImageWrapper>
            <ParseContent content={fields.description || ''} />
          </Block>
        </div>
      </div>
    </div>
  </StyledSection>
)

export default SeeAllVacancies
