import React from 'react'

// Components
import SeeAllVacancies from 'components/flex/Vacancies/SeeAllVacancies'

interface VacanciesShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_FlexHome_Text
  location?: any
}

interface PostProps {
  [key: string]: any
}

const VacanciesShell: React.FC<VacanciesShellProps> = ({
  fields,
  location = {},
}) => {
  const types: PostProps = {
    seeallvacancies: SeeAllVacancies,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default VacanciesShell
